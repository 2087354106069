@import "/styles/tools/";

.ihbContent {
  $this: &;

  display: flex;
  flex-flow: column-reverse;
  gap: rem(24);

  @include above(mdlg) {
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: rem(133);
    row-gap: rem(40);
  }

  &.noImage {
    min-height: rem(300);
    gap: rem(0);

    #{$this}__imageBlock {
      display: none;
    }

    #{$this}__content {
      @include above(mdlg) {
        justify-content: flex-start;
        max-width: rem(650);
      }
    }
  }

  &.splitText {
    #{$this}__content {
      @include above(md) {
        justify-content: space-between;
        max-width: 100%;
      }
    }

    #{$this}__cta {
      @include above(md) {
        margin-top: rem(40);
      }
    }
  }

  &__imageBlock {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column;

    @include above(mdlg) {
      flex: 1 1 calc(60% - rem(133));
    }
  }

  &__content {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column;

    @include above(mdlg) {
      flex: 1 1 33%;
    }

    @include above(md) {
      justify-content: space-between;
      max-width: rem(650);
    }
  }

  &__copy {
    @include rte(true, true);
    @include typeBody;
    margin-top: rem(16);
    color: token("secondary-on-general-light");

    @include above(md) {
      @include typeBody(20);
      margin-top: rem(24);
    }

    &.textColumns {
      @include above(md) {
        columns: 2;
      }
    }

  }

  .textContent {
    padding-right: rem(32);

    @include above(mdlg) {
      padding-right: rem(64);
    }
  }

  &__cta {
    margin-top: rem(24);
  }

  &__heading {
    @include typeHeadline(24);
    color: token("general-dark");

    @include above(md) {
      @include typeHeadline(32);
    }
  }

  &__imageContainer {
    @include aspect-ratio(15, 10);
    flex: 1;
    position: relative;
    border-radius: rem(4);
    overflow: hidden;

    @include above(md) {
      height: 0;
      padding-top: calc((10/15 * 100) * 1%);
    }

    picture {
      position: absolute;
      inset: 0;
    }

    img {
      object-fit: cover;
    }

  }

  &__lightbox {
    position: relative;

    &:hover,
    &:focus-visible {
      cursor: pointer;

      &:after {
        background-color: token("interaction-on-general-dark");
      }
    }

    &:after {
      position: absolute;
      right: rem(8);
      bottom: rem(8);
      z-index: 1;
      transition: transform 0.25s ease-in-out;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.75 4.41602H9.83824V6.07691L6.59169 6.08062L11.3536 10.8425L10.1765 12.0196L5.4146 7.2577L5.41089 10.5043H3.75V4.41602Z' fill='%231C1B1F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.75 20.916H9.83824V19.2551L6.59169 19.2514L11.3536 14.4895L10.1765 13.3125L5.4146 18.0743L5.41089 14.8278H3.75V20.916Z' fill='%231C1B1F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.25 4.41602L20.25 10.5043L18.5891 10.5043L18.5854 7.2577L13.8235 12.0196L12.6464 10.8425L17.4083 6.08062L14.1618 6.07691L14.1618 4.41602L20.25 4.41602Z' fill='%231C1B1F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.25 20.916L20.25 14.8278L18.5891 14.8278L18.5854 18.0743L13.8235 13.3125L12.6464 14.4895L17.4083 19.2514L14.1618 19.2551L14.1618 20.916L20.25 20.916Z' fill='%231C1B1F'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-size: rem(16);
      background-repeat: no-repeat;
      background-color: token("interaction-on-general-dark");
      width: rem(24);
      height: rem(24);
      pointer-events: none;
      content: '';
      color: token("general-dark");

      @include above(md) {
        right: rem(16);
        bottom: rem(16);
        width: rem(40);
        height: rem(40);
        background-size: auto;
      }
    }

  }

  &__image {
    display: block;
    object-fit: cover;
  }

  &__caption {
    @include typeLabel(12);
    position: relative;
    padding-left: rem(12);
    margin-top: rem(10);
    border-left-width: rem(2);
    border-left-style: solid;
    border-left-color: token("general-divide-feature-2");
    color: token("secondary-on-general-light");

    @include above("md") {
      @include typeLabel(16);
      margin-top: rem(8);
      max-width: calc(rem(418) * 1.5);
    }

    &.lightPurple,
    &.secondaryPurple {
      border-left-color: token("general-manage");
    }

    &.lightYellow,
    &.secondaryYellow {
      border-left-color: token("general-perform");
    }

    &.lightPink,
    &.secondaryPink {
      border-left-color: token("general-integrate");
    }

    &.lightOrange,
    &.secondaryOrange {
      border-left-color: token("general-tbc");
    }

    &.lightGreen,
    &.secondaryGreen {
      border-left-color: token("general-comply");
    }
  }
}