@import "/styles/tools/";

.tabTitle {
    & + & {
        margin-left: rem(24);
    }

    &__link {
        @include reset-button;
        @include typeLabel(16, "bold");
        padding: rem(16) 0;
        display: block;
        color: token("secondary-on-general-light");
        cursor: pointer;
        border-bottom: 4px solid transparent;
        transition: ease 0.2s;

        &:hover {
            border-bottom-color: transparentize(token("interaction-on-general-dark"), 0.75);
        }

        &.active {
            color: token("primary-on-general-light");
            border-bottom-color: token("interaction-on-general-dark");
        }
    }
}