@import "/styles/tools/";

.tabs {
    &__list {
        display: flex;
        flex-flow: row;
        border-bottom: 1px solid token("general-divide-light");
        list-style: none;
        overflow: auto;
        scroll-snap-type: x mandatory;
        scrollbar-width: thin;

        &>* {
            scroll-snap-align: start;
        }
    }

    &__stage {
        margin-top: rem(40);

        @include above(md) {
            margin-top: rem(64);
        }
    }
}