@import "/styles/tools/";

.accordion {
  $this: &;
  position: relative;

  &__heading {
    @include typeHeadline(24);
    color: token("secondary-on-general-light");
    margin-bottom: 1em;

    @include above(md) {
      @include typeHeadline(32);
    }
  }

  &__inner {
    @include container;
    @include vertical-spacing;
  }

  &__item {
    display: block;
    position: relative;
    width: 100%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: rem(16);
  }

  &__itemTitle {
    display: block;
    width: 100%;
    max-width: 100%;
    @include typeHeadline(18);
    position: relative;
    padding: rem(16) rem(60) rem(16) rem(20);
    background-color: token("general-dark");
    color: white;
    font-weight: 600 !important;
    text-decoration: none;
    text-align: left;

    @include above(md) {
      @include typeHeadline(24);
      padding: rem(16) rem(40);
    }

    &:focus-visible {
      outline: 0 !important;
      box-shadow: 0 0 0 rem(3) color("legacy-blue") !important;
    }

    &:hover {
      cursor: pointer;
    }

    &:after {
      content: '';
      width: rem(24);
      height: rem(24);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M20 13H13V20H11V13H4V11H11V4H13V11H20V13Z' fill='white'/%3E%3C/svg%3E");
      background-size: rem(24);
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      right: rem(16);
      top: 50%;
      transform: translateY(-50%);
      display: block;
    }

    @include above (md) {
      &:after {
        right: rem(40);
      }
    }

    &.active {
      &:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4 11H20V13H4V11Z' fill='white'/%3E%3C/svg%3E%0A");
      }
    }

  }

  &__itemContent {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.5s ease-in-out;
    background: token('general-accent');

    &.active {
      grid-template-rows: 1fr;
    }
  }

  &__contentWrapper {
    overflow: hidden;

    &>div,
    section {
      padding: rem(20) !important;

      @include above(md) {
        padding: rem(40) !important;
      }
    }

    // Simple Text Overrides
    [class*="ComponentFiftyFiftyCallout_branded_"] {
      background-color: token("general-accent") !important;

      [class*="ComponentFiftyFiftyCallout_fiftyFiftyCallout__imageWrapper_"] {
        @include below(md) {
          border-radius: rem(4) !important;
        }
      }

      [class*="ComponentFiftyFiftyCallout_fiftyFiftyCallout__content_"] {
        padding-left: 0 !important;
        padding-right: 0 !important;
        background-color: token("general-accent") !important;

        @include below(md) {
          top: rem(6) !important;
          border-radius: 0;
        }
      }

      [class*="ComponentFiftyFiftyCallout_collapsed_"] {
        &:after {
          background: linear-gradient(rgba(255, 255, 255, 0), token("general-accent")) !important;
        }
      }

      [class*="ComponentFiftyFiftyCallout_right_"] {
        [class*="ComponentFiftyFiftyCallout_fiftyFiftyCallout__content_"] {
          @include above(md) {
            padding-left: 0 !important;
            padding-right: rem(40) !important;
          }
        }
      }

      [class*="ComponentFiftyFiftyCallout_left_"] {
        [class*="ComponentFiftyFiftyCallout_fiftyFiftyCallout__content_"] {
          @include above(md) {
            padding-left: rem(40) !important;
            padding-right: 0 !important;
          }
        }
      }

    }

    [class*="ComponentFiftyFiftyCallout_fiftyFiftyCallout__image_"],
    [class*="ComponentFiftyFiftyCallout_fiftyFiftyCallout__video_"] {
      border-radius: rem(4) !important;
    }

    [class*="ComponentFiftyFiftyCallout_standard_"] {
      background-color: token("general-accent") !important;

      [class*="ComponentFiftyFiftyCallout_fiftyFiftyCallout__content_"],
      [class*="ComponentFiftyFiftyCallout_fiftyFiftyCallout__imageColumn_"] {
        background-color: token("general-accent") !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      [class*="ComponentFiftyFiftyCallout_collapsed_"] {
        background-color: token("general-accent") !important;

        &:after {
          background: linear-gradient(rgba(255, 255, 255, 0), token("general-accent"));
        }
      }

    }

    // Featured Items Overrides

    [class*="ComponentFeaturedItems_featuredItems__item_"]:not([class*="ComponentFeaturedItems_keyline_horizontal"]) {
      padding-left: rem(32) !important;
      padding-right: rem(16) !important;
    }

    [class*="ComponentFeaturedItems_keyline_horizontal"] {
      [class*="ComponentFeaturedItems_featuredItems__itemHeadingWrapper_"] {
        gap: rem(16) !important;
      }

      [class*="ComponentFeaturedItems_featuredItems__icon_"] {
        align-self: flex-start;
      }

    }

    [class*="ComponentFeaturedItems_featuredItems__itemContent_"] {
      @include above(md) {
        font-size: rem(16) !important;
        line-height: 180% !important;
        letter-spacing: -0.01em !important;
      }
    }

    [class*="ComponentFeaturedItems_layout4wide"] {
      @include above(md) {
        grid-template-columns: repeat(2, minmax(200px, 1fr)) !important;
      }

      @media (min-width: 1100px) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
      }
    }

  }

}

.hiddenContent {
  overflow: hidden;
  animation: visibilityhide 0.4s linear forwards;
}

@keyframes visibilityhide {
  0% {
    pointer-events: none;
    visibility: visible;
  }

  99% {
    pointer-events: none;
    visibility: visible;
  }

  100% {
    pointer-events: none;
    visibility: hidden;
  }
}