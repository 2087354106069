@import '/styles/tools/';

.featureTabs {
    @include vertical-spacing("lg");

    &.accent {
        background-color: token("general-accent");
    }

    &.light {
        background-color: token("general-light");
    }

    &__container {
        @include container;
    }

    &__heading {
        @include typeHeadline(24);
        margin-bottom: rem(40);
        color: token("secondary-on-general-light");

        @include above("md") {
            @include typeHeadline(32);
        }
    }
}

.contentTabs {
    $this: &;
    padding-top: rem(16);

    @include above("md") {
        padding-top: rem(40);
    }

    &.light {
        background-color: token("general-accent");
    }

    &.dark {
        background-color: token("general-dark");

        #{$this}__tab {
            border-left: rem(1) solid token("general-divide-dark");

            &:hover {

                #{$this}__tabHeading,
                #{$this}__tabSubheading {
                    color: token("primary-on-general-dark");
                }
            }

            &.activeTab {
                &:hover {

                    #{$this}__tabHeading,
                    #{$this}__tabSubheading {
                        color: token("primary-on-general-light");
                    }
                }
            }
        }
    }

    &__container {
        @include above("md") {
            @include container;

        }
    }

    &__tabWrapper {
        display: flex;
        overflow: auto;
        scroll-snap-type: x mandatory;
        scrollbar-width: thin;
    }

    &__tab {
        display: flex;
        flex-direction: column;
        width: fit-content;
        min-width: rem(148);
        max-width: rem(350);
        min-height: 100%;
        padding: rem(16);
        border-left: rem(1) solid token("general-divide-light");
        scroll-snap-align: start;

        @include below("md") {
            text-align: center;
        }

        @include above("md") {
            padding: rem(16) rem(24);
            min-width: rem(236);
        }

        &:first-child {
            border-left: rem(1) solid transparent;
        }

        &:hover {
            cursor: pointer;
        }

        &.activeTab {
            background-color: token("primary-on-general-dark");
            border-left: rem(1) solid token("primary-on-general-dark");

            // remove the left border from the next tab
            &+#{$this}__tab {
                border-left: rem(1) solid transparent;
            }

            #{$this}__tabHeading,
            #{$this}__tabSubheading {
                color: token("primary-on-general-light");
            }
        }

        &:hover,
        &:focus-visible {

            #{$this}__tabHeading,
            #{$this}__tabSubheading {
                color: token("primary-on-general-light");
            }
        }
    }

    &__tabHeading {
        @include typeHeadline(32);
        color: token("secondary-on-general-light");

        @include below("md") {
            @include typeHeadline(24);
        }
    }

    &__tabSubheading {
        @include typeLabel(14);
        color: token("secondary-on-general-light");

        @include below("md") {
            display: none;
        }
    }

    &__contentContainer {
        background-color: token("primary-on-general-dark");
        border-bottom: rem(1) solid token("general-divide-light");

        [class*="ComponentFiftyFiftyCallout_branded"] {
            background-color: token("primary-on-general-dark");

            @include above("md") {
                padding-top: rem(64);
                @include container;
            }

            [class*="ComponentFiftyFiftyCallout_fiftyFiftyCallout__content_"] {
                background-color: token("primary-on-general-dark");

            }

            [class*="ComponentFiftyFiftyCallout_collapsed_"] {
                &:after {
                    background: linear-gradient(rgba(255, 255, 255, 0), token("general-light"));
                }
            }

            [class*="ComponentFiftyFiftyCallout_fiftyFiftyCallout__imageWrapper_"] img {
                @include above("md") {
                    border-radius: rem(4) !important;
                }
            }
        }
    }

    &__content {
        display: none;

        &.activeContent {
            display: block;
        }

        [class*="ComponentFiftyFiftyCallout_branded_"] {
            &:last-child {
                @include above("md") {
                    margin-bottom: rem(64);
                }
            }
        }

        div:not([class*="ComponentAccordion_accordion__item_"]){
            [class*="ComponentFiftyFiftyCallout_branded_"] {
                &:last-child {
                    @include above("md") {
                        margin-bottom: unset;
                    }
                }
            }
        }

    }


}